<template>
    <CForm @submit.prevent="create" method="POST">
      <CCard no-header>
        <CCardBody>
          <h3 v-text="method"></h3>
          <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
          <CRow>
            <CCol col="10"></CCol>
            <CCol class="text-right">
              <div><label>Active</label></div>
              <CSwitch color="success"  size="lg"  :checked.sync="contract.active"></CSwitch>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput disabled label="Description" type="text" v-model="contract.description"></CInput>
            </CCol>
            <CCol sm="6">
              <CInput disabled  label="Partner Name" type="text" v-model="contract.partnerName"></CInput>
            </CCol>
            <CCol sm="6">
              <CInput label="Comission Rate" type="text" v-model="contract.commissionRate"></CInput>
            </CCol>
            <CCol sm="6" v-if="isCreate">
                <CSelect  label="Partner" @change="onChange($event)" :value.sync="contract.partnerId"  :plain="true"  :options="partners" placeholder="Select a partner" >
                </CSelect>        
             </CCol>
          </CRow>
  
          <CRow>
            <CCol col="9"></CCol>
            <CCol class="d-flex justify-content-end">
              <CButton color="secondary"  @click="goBack" style="margin-right: 15px"><CIcon name="cil-list" /> Back to the list</CButton
              >
              <CButton color="primary" type="submit"><CIcon name="cil-save" /> Save</CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "CreateOrUpdatePartner",
    data: () => {
      return {
        isCreate : false,
        emptyGuid: "00000000-0000-0000-0000-000000000000",
        method: "Create New Partner",
        partners: [],
        contract: {
          description: "",
          id:"00000000-0000-0000-0000-000000000000",
          active: true,
          commissionRate: 0,
          createDate: null,
          endDate: "",
          partnerId:"",
          donorPartnerId:"",
          partnerName: null
        },
        // Alert
        alertType: "success",
        message: null,
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      handleFileUpload(event) {
        this.partner.file = event.target.files[0];
      },
      onChange(event)
      {
        if(this.contract.partnerId != null){
            this.contract.partnerName = this.partners.find(x=>x.value == this.contract.partnerId)?.label;
        }
        
      },
      create() {
        let self = this;
        let form = new FormData();
        form.append("description", self.contract.description);
        form.append("active", self.contract.active);
        form.append("commissionRate", self.contract.commissionRate);
        form.append("partnerName", self.contract.partnerName);
        form.append("partnerId", self.contract.partnerId);
        if(self.contract.endDate === null)
        {
            self.contract.endDate = "";
        }
        if(self.contract.createDate === null)
        {
            self.contract.createDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');
        }
        form.append("createDate", self.contract.createDate);
        form.append("endDate", self.contract.endDate);
        if(self.isCreate == false)
        {
            form.append("id", self.contract.id);
        }

        var link = "/v1/Contract";
        if(self.isCreate == true)
        {        
            form.append("donorPartnerId",self.emptyGuid);  
            link = "/v1/Contract/Create"
        } else{
            form.append("donorPartnerId",self.contract.donorPartnerId);  
        }

        axios
          .post(this.$apiAdress + link, form)
          .then((response) => {
            self.contract.id = response.data;
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully updated partner.";
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
  
      getContract(id) {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/Contract/" + id)
          .then(function (response) {
            self.contract = response.data;
          })
          .catch(function (error) {
            this.message = error;
          });
      },
      getPartners(){
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/Partner")
          .then(function (response) {

            response.data.map(function (value,key) {
             value.value = value.id;
             value.label = value.name;
             self.partners.push(value);
            });
          })
          .catch(function (error) {
            this.message = error;
          });
      }
    },
  
    mounted: function () {
      let self = this;
      // Edit OR Create
      if (self.$route.params.id != this.emptyGuid) {
        self.method = "Edit Contract";
        self.getContract(self.$route.params.id);
      } else{
        self.isCreate = true;
      }
      self.getPartners();
    },
  };
  </script>
  